<template>
  <div>
    <v-col align="center">
      <v-card max-width="500px">
        <v-toolbar color="secondary lighten-5" class="mb-5" flat prominent>
          <v-col class="text-center">
            <v-img src="/media/logos/tkt-vivax-logo.png" contain></v-img>
          </v-col>
          <template #extension>
            <v-row>
              <v-col>
                <h1 class="text--secondary">DICLINA Sales Navigator</h1>
              </v-col>
            </v-row>
          </template>
        </v-toolbar>
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-card-text>
            <v-row no-gutters class="mb-5">
              <v-col>
                <span class="display-1">Passwort ändern</span>
              </v-col>
            </v-row>
            <form-change-password v-model="formData" />
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              block
              large
              :disabled="invalid"
              :loading="loading"
              @click="changePassword"
            >
              <v-icon class="mr-2">mdi-login-variant</v-icon>
              Passwort ändern
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn color="secondary" block text to="/login">
              Zurück zum Login
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import FormChangePassword from "@/components/forms/FormChangePassword";

export default {
  name: "ChangePassword",
  components: { FormChangePassword },
  data() {
    return {
      loading: false,
      formData: {
        token: this.token,
      },
    };
  },
  methods: {
    changePassword() {
      this.loading = true;
      this.$store
        .dispatch("changePassword", this.formData)
        .then(() => {
          this.loading = false;
          this.$router.push("/");
        })
        .catch(({ response }) => {
          switch (response.status) {
            case 429:
              this.$refs.form.setErrors({
                passwordRepeated:
                  "Zu viele Anfragen. Bitte warten Sie einen Augenblick.",
              });
              break;
            case 422:
              this.$refs.form.setErrors({
                passwordRepeated: response.data.message,
              });
              break;
            default:
              this.$refs.form.setErrors({
                passwordRepeated: response.data.message,
              });
              break;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
